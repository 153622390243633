import React,{useState,useEffect} from 'react'
import LoginForm from './components/LoginForm';
import logo from "../../images/new/logo.svg"
import { Spin } from "antd";
import "./Login.css";
import {navigate} from 'gatsby'
import LoginImg from './components/LoginImg';

const LoginOne = props => {
	const [loading,setLoading]=useState(true)
	useEffect(() => {
			if(localStorage.getItem ('token') !== null) {
				navigate('/')
			  }else{
				  setTimeout(()=>{
					setLoading(false)
				  },600)
				
			  }
	  }, []);
	  if(!loading){
	return (
		<section className='wash_auth_section'>
          <LoginImg/>
          <div className='wash_auth_right'>
			  <div className='wash_auth_right_logo'><img src={logo} alt="logo"/></div>
		      <LoginForm {...props} />
		  </div>
		</section>
	)}else{
		return(<div className="spinner-main"><Spin/></div>)
	}
}

export default LoginOne
